.Sider_nav__16zgo {
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center; }

.Sider_logo__1482e {
  width: 30px;
  height: 30px;
  border-radius: 3px; }

.Sider_title__j3Z7B {
  padding-left: 10px; }

.Sider_titleHide__ejzef {
  display: none; }

.condition {
  display: flex; }
  .condition .ant-form-item {
    display: flex; }
  .condition .ant-form-item-control-wrapper {
    flex: 1 1; }
  .condition .ant-row div:not(:first-of-type) .ant-form-item-label {
    margin-left: 10px; }

.condition-fields {
  flex-grow: 1; }
  .condition-fields .ant-row {
    max-width: 1024px; }

.condition-search {
  width: 220px;
  align-self: flex-end;
  margin-bottom: 27px;
  text-align: right; }

.mi-modal .ant-modal-content {
  border: 1px solid #E8E8E8; }

.mi-modal .ant-modal-body {
  padding: 0; }

.mi-modal .mi-modal-body {
  padding: 24px; }

.mi-modal .mi-modal-footer {
  border-top: 1px solid #E8E8E8;
  background-color: #F7F9FA;
  height: 46px;
  line-height: 46px; }
  .mi-modal .mi-modal-footer button {
    border: none;
    outline: none;
    background-color: transparent;
    width: 130px;
    cursor: pointer; }
    .mi-modal .mi-modal-footer button.ensure {
      color: #1890FF; }
  .mi-modal .mi-modal-footer.mi-modal-footer-patch button {
    width: 233px; }

.patch-table td {
  word-break: break-all; }

.traffic-table-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between; }

.traffic-patch-pop-content {
  width: 256px;
  line-height: 20px; }
  .traffic-patch-pop-content > div:not(:first-child) {
    border-top: 1px solid #e8e8e8;
    padding-top: 12px; }
  .traffic-patch-pop-content .traffic-patch-pop-action {
    font-weight: bold; }
  .traffic-patch-pop-content .tip {
    font-size: 12px; }
  .traffic-patch-pop-content span {
    display: inline-block;
    width: 42px;
    text-align: center; }

.ant-popover-inner-content {
  padding-left: 0;
  padding-right: 0; }

.navLogo {
  color: #fff; }

.ant-message {
  top: auto;
  bottom: 16px; }
  .ant-message .ant-message-notice-content {
    padding: 0; }
  .ant-message .ant-message-success, .ant-message .ant-message-error, .ant-message .ant-message-warning {
    padding: 10px 16px;
    border-radius: 4px;
    color: #fff; }
    .ant-message .ant-message-success .anticon, .ant-message .ant-message-error .anticon, .ant-message .ant-message-warning .anticon {
      color: #fff; }
  .ant-message .ant-message-success {
    background-color: #52C41A; }
  .ant-message .ant-message-error {
    background-color: #f5222d; }

.mi-little-circle {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-right: 4px; }
  .mi-little-circle.green {
    background-color: #52C41A; }
  .mi-little-circle.blue {
    background-color: #1890FF; }

.mi-empty-row .ant-table-selection-column span {
  visibility: hidden; }


body {
  background-color: #eff3f4;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
