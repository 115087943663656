.navLogo {
  color: #fff;
}

// 修改antd全局样式
.ant-message {
  top: auto;
  bottom: 16px;
  .ant-message-notice-content{
    padding: 0;
  }
  .ant-message-success, .ant-message-error, .ant-message-warning {
    padding: 10px 16px;
    border-radius: 4px;
    color: #fff;
    .anticon {
      color: #fff;
    }
  }
  .ant-message-success {
    background-color: #52C41A;
  }
  .ant-message-error {
    background-color: #f5222d;
  }
}

.mi-little-circle {
  display: inline-block;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.25);
  vertical-align: middle;
  margin-right: 4px;
  &.green {
    background-color: #52C41A;
  }
  &.blue {
    background-color: #1890FF;
  }
}

.mi-empty-row {
  .ant-table-selection-column span{
    visibility: hidden;
  }
}
