.nav {
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 30px;
  height: 30px;
  border-radius: 3px;
}

.title {
  padding-left: 10px;
}

.titleHide {
  display: none;
}