.mi-modal {
  .ant-modal-content {
    border: 1px solid #E8E8E8;
  }
  .ant-modal-body {
    padding: 0;
  }
  .mi-modal-body {
    padding: 24px;
  }
  .mi-modal-footer {
    border-top: 1px solid #E8E8E8;
    background-color: #F7F9FA;
    height: 46px;
    line-height: 46px;
    button {
      border: none;
      outline: none;
      background-color: transparent;
      width: 130px;
      cursor: pointer;
      &.ensure {
        color: #1890FF;
      }
    }
    &.mi-modal-footer-patch {
      button {
        width: 233px;
      }
    }
  }
}

.patch-table td{
  word-break: break-all;
}