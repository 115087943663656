.condition {
  display: flex;
  .ant-form-item {
    display: flex;
  }
  .ant-form-item-control-wrapper {
    flex: 1;
  }
  .ant-row div:not(:first-of-type) .ant-form-item-label{
    margin-left: 10px;
  }
}

.condition-fields {
  flex-grow: 1;
  .ant-row {
    max-width: 1024px;
  }
}

.condition-search {
  width: 220px;
  align-self: flex-end;
  margin-bottom: 27px;
  text-align: right;
}