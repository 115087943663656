.traffic-table-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.traffic-patch-pop-content {
  width: 256px;
  line-height: 20px;
  > div:not(:first-child) {
    border-top: 1px solid #e8e8e8;
    padding-top: 12px;
  }
  .traffic-patch-pop-action {
    font-weight: bold;
  }
  .tip {
    font-size: 12px;
  }
  span {
    display: inline-block;
    width: 42px;
    text-align: center;
  }
}

// 实现找不到可以起类名的地方
.ant-popover-inner-content {
  padding-left: 0;
  padding-right: 0;
}